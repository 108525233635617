<template>
<Header />
  <div class="container">
    
    <div>
        <h2>Edit Todo</h2>
        <form @submit.prevent="update" class="text-justify">
            <div>
                <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Title" v-model="form.title" required />
            </div>
            <div>
                <Editor v-model="form.content" editorStyle="height: 320px" placeholder="Todo Description" required/>
            </div>

            <div class="p-field p-col-12 p-md-4">
                <label for="assignee">Assign To</label>
                <div>
                    <select :disabled=ploNoStf name="selval" id="assignee" @change="handleChange" v-model="form.assignedToEmail" class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus">
                        <!-- <option value="Select Staff" disabled>Select Staff</option> -->
                        <option v-for="staff in allUsers" :key="staff.id" :value="staff.email">
                            {{ staff.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="time12">Due Date</label>
                <!-- <Calendar id="time12" v-model="form.due" :timeOnly="true" hourFormat="12" :inline="true"/> -->
                <Datepicker id="time12" v-model="due" inputFormat="dd-MMMM-yyyy" />
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="status">Status</label>
                <div>
                    <select id="status" v-model="form.status" class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus">
                        <!-- <option value="Select Staff" disabled>Select Staff</option> -->
                        <option value="pending">pending</option>
                        <option value="ongoing">ongoing</option>
                        <option value="completed">completed</option>
                    </select>
                </div>
            </div>

            <Button label="Update Todo" type="submit" class="p-mt-2" />
            <router-link class="no-decor" style="text-decoration:none" :to="`/todos`">
              <Button label="Cancel" class="p-mt-2 p-ml-2" />
            </router-link>
        </form>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import {reactive, computed, onMounted} from 'vue'
import {ref} from 'vue';
import {useRoute, useRouter} from 'vue-router'
import {getTodo, updateTodo, useLoadUsers} from '@/fbDomain'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Editor from 'primevue/editor';
import firebase from 'firebase'
import Datepicker from 'vue3-datepicker'

export default {
  components: {Header, InputText, Button, Editor, Datepicker},
  setup() {
    const router = useRouter()
    const route = useRoute()
    const todoId = computed(() => route.params.id)

    const allUsers = useLoadUsers()
    const currUserEmail = localStorage.getItem("ploUserEmail")
    const currUserName = localStorage.getItem("ploUserName")


    const form = reactive({title:'', content:'', assignedToEmail: currUserEmail, status: 'pending' })

    // const assignedBy = ref()
    // const assignDate = ref()
    // const assignedByEmail = ref()
    let assignedTo = ref(currUserName)
    let due = ref(new Date())
    // const fmtDate = ref()
    const ploType = ref("");
    const ploNoStf = ref();
    ploType.value= localStorage.getItem("ploType");
    ploType.value !== "stf" ? ploNoStf.value = false : ploNoStf.value = true

    const handleChange = (e) =>{
        var name = e.target.options[e.target.options.selectedIndex].text;
        // console.log(name);
        assignedTo.value = name
    }

    onMounted(async () => {
        const todo = await getTodo(todoId.value)
        form.title = todo.title
        form.content = todo.content
        form.assignedToEmail = todo.assignedToEmail
        form.status = todo.status
        assignedTo.value =  todo.assignedTo
        // assignedBy.value = todo.assignedBy
        // assignedByEmail.value = todo.assignedByEmail
        due.value = todo.due.toDate()
    })

    const update = async () => {
        await updateTodo(todoId.value, 
        { ...form, 
            updatedBy: currUserName, 
            updatedByEmail: currUserEmail, 
            updatedAt: firebase.firestore.Timestamp.fromDate(new Date()), 
            due: firebase.firestore.Timestamp.fromDate(due.value),
            assignedTo: assignedTo.value
        })
        router.push('/todos')
        form.title =''
        form.content =''
        form.assignedToEmail = currUserEmail
        form.status = 'pending'
        assignedTo.value =  currUserName
    }

    return {form, update, allUsers, currUserEmail,currUserName, due,handleChange,ploNoStf}
  },
}
</script>

